<template>

    <div class="con-wrap">
        <VehicleReservationViewPopup :idx="pop.idx" :tdate="pop.tdate" v-if="pop.isShowSchedule" @close="pop.hideSchedule"></VehicleReservationViewPopup>
        <VehicleReservationGuidePopup v-if="pop.isShowGuide" @close="pop.isShowGuide=false"></VehicleReservationGuidePopup>

        <CarrotTitle title="차량운행 예약일지">
            <div class="btn-ibbox title-btnbox float-right">
                <button @click="pop.isShowGuide=true" class="btn-default">차량관제 시스템 이용가이드</button>
            </div>
            - 업무용 차량은 <span class="color-1">개인용도로 사용할 수 없습니다.</span><br>
            - 차량 이용 시, 차에 비치된 <span class="color-1">운행일지를 반드시 기록</span>하여 주시기 바랍니다.<br>
            - <span class="color-1">주말에 차량 이용 시 전자결재로 차량 사용 신청서</span>를 올려주세요. (전자결재 > 복리후생 > 차량 사용 신청서)
        </CarrotTitle>
        <div class="board">
            <div class="mt-40">
                <div>

                    <div class="day-select">
                        <button @click="bbs.doPrev" class="btn btn-prev">prev</button>
                        <!-- <button class="day btn-cal">{{ bbs.today.substring(0, 7) }}</button> -->
                        <button @click="bbs.showCalendar" class="day btn-cal">
                            {{ bbs.today.substring(0, 7) }}
                            <v-datepicker v-model="bbs.tdate" @dayclick="bbs.doCalculate" class="datepicker-group">
                                <template #default="{ inputValue, togglePopover }">
                                    <div>
                                        <input type="text" :value="inputValue" style="display:none;" />
                                        <button class="btn-month" @click="togglePopover()"></button>
                                    </div>
                                </template>
                            </v-datepicker>
                        </button>
                        <button @click="bbs.doNext" class="btn btn-next">next</button>
                    </div>

                    <div class="dp-table w-100per mt-40 mb-20">
                        <!-- <button @click="bbs.showDate" class="btn-default float-left w-70px">일</button> -->
                        <select v-model="bbs.stype" @change="bbs.changePage()" class="btn-default float-left w-70px">
                            <option value="daily">일</option>
                            <option value="monthly">월</option>
                        </select>
                        <button @click="bbs.addSchedule()" class="btn-default float-right">차량 예약</button>
                    </div>

                    <CarrotCalendar :daysinfo="bbs.list" :ymd="bbs.today" @selected="bbs.doSelect"></CarrotCalendar>

                </div>
            </div>
        </div>
    </div>

</template>

<script>
// @ is an alias to /src 
import { onMounted, reactive } from 'vue'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import { DatePicker } from 'v-calendar'
import CarrotCalendar from '@/components/common/CarrotCalendar.vue'
import VehicleReservationViewPopup from '@/components/popup/ResourceManagement/VehicleReservationViewPopup.vue'
import VehicleReservationGuidePopup from '@/components/popup/ResourceManagement/VehicleReservationGuidePopup.vue'

export default {
    layout:"ResourceManagement",
    components: {
        'v-datepicker' : DatePicker,
        CarrotCalendar,
        VehicleReservationViewPopup,
        VehicleReservationGuidePopup,
    },
    setup() {
        const router = useRouter();
        const toast = useToast()

        const pop = reactive({
            idx   : 0,
            tdate : "",
            // Popup 노출 여부
            isShowSchedule  : false,
            isShowGuide     : false,

            showGuide : () => {
                let href = "http://old.carrotians.net/intranet_snew/car_guide_pop.html";
                window.open(href, '_blank').focus();
            },

            showSchedule : (idx) => {
                pop.idx   = idx;
                pop.tdate = bbs.today;
                pop.isShowSchedule = true;
            },
            hideSchedule : () => {
                pop.isShowSchedule = false;
                pop.idx = 0;
                bbs.doSearch();
            },
        });

        const bbs = reactive({
            today : "",
            stype : "monthly",

            list : [], total : 0,

            doCalculate : () => {
                let nd = new Date(bbs.tdate);
                bbs.today = [nd.getFullYear(), nd.getMonth()>8?nd.getMonth()+1:'0'+(nd.getMonth()+1), nd.getDate()>9?nd.getDate():'0'+nd.getDate()].join('-');
                bbs.doSearch();
            },

            doPrev : () => {
                let td = new Date(bbs.today);
                let nd = new Date(td.getFullYear(), td.getMonth()-1, td.getDate());
                bbs.today = [nd.getFullYear(), nd.getMonth()>8?nd.getMonth()+1:'0'+(nd.getMonth()+1), nd.getDate()>9?nd.getDate():'0'+nd.getDate()].join('-');

            },

            doNext : () => {
                let td = new Date(bbs.today);
                let nd = new Date(td.getFullYear(), td.getMonth()+1, td.getDate());
                bbs.today = [nd.getFullYear(), nd.getMonth()>8?nd.getMonth()+1:'0'+(nd.getMonth()+1), nd.getDate()>9?nd.getDate():'0'+nd.getDate()].join('-');
                bbs.doSearch();

                sessionStorage.setItem('VehicleReservationLogMonth', JSON.stringify({
                    today : bbs.today
                }));
            },

            changePage : () => {
                if( bbs.stype == "daily" ){
                    bbs.showDate();
                }
            },

            showDate : () => {
                sessionStorage.setItem('VehicleReservationLogMonth', JSON.stringify({
                    today : bbs.today
                }));
                router.push({
                    name : 'ResourceManagement-VehicleReservationLogDay'
                });
            },

            addSchedule : (cdate) => {
                sessionStorage.setItem('VehicleReservationLogMonth', JSON.stringify({
                    today : cdate?cdate:bbs.today
                }));
                if( !cdate ){
                    sessionStorage.removeItem('VehicleReservationAdd');
                } else {
                    sessionStorage.setItem('VehicleReservationAdd', JSON.stringify({
                        today : cdate,
                    }));
                }

                router.push({
                    name : 'ResourceManagement-VehicleReservationAdd'
                });
            },

            doSearch : () => {
                let params = {
                    tgdate : bbs.today
                };
                axios.get('/rest/resourceManagement/getVehicleReserveMonth', { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        bbs.list  = res.data.list;
                        bbs.total = res.data.total;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                })
            },

            doSelect: (o) => {
                if( Number(o.idx) > 0 ){
                    pop.showSchedule(o.idx);
                } else {
                    bbs.addSchedule(o.cdate);
                }
            },

        });

        onMounted(() => {
            // Mounted
            let nd = new Date();
            let today = nd.getFullYear()
                    + '-' + (nd.getMonth()>8?nd.getMonth()+1:'0'+(nd.getMonth()+1))
                    + '-' + (nd.getDate()>9?nd.getDate():'0'+nd.getDate());
            bbs.today = today;

            let ss = sessionStorage.getItem('VehicleReservationLogMonth');
            if( ss ){
                ss = JSON.parse(ss);
                if( ss.today && new Date(ss.today) != 'Invalid Date' ) {
                    bbs.today = ss.today;
                }
            }

            // bbs.list = [ 
            //     {"cdate" : "2021-07-01", "idx" : 3, "text" : "노출될 텍스트1"}, 
            //     {"cdate" : "2021-07-01", "idx" : 4, "text" : "노출될 텍스트2"}, 
            // ];
            bbs.doSearch();
        });

        return {bbs, pop};
    }
}
</script>

<style lang="scss" scoped>
.btn-month {
    position: absolute; top: 0; left: 0; width: 100%; height: 100%; border: none; opacity: 0.001; z-index: 1;
}
</style>